<template>
  <div id="detail-page-main">
   
    <div
      class="main-layout"
      v-if="dataFetched"
      :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
    >

    
        <div class="content-container" id="detail-page-main">
        <div class="content-detail">
          <div
            :class="[
              localDisplayLang == 'ara' ? 'contentTags' : 'contentTagsRight',
            ]"
            v-if="
              isObjectTag(contentDetails) && pickTagForContent(contentDetails)
            "
          >
            <p class="tag">{{ $t(pickTagForContent(contentDetails)) }}</p>
          </div>
           <BreadCrumbs 
        :activeTitle="contentDetails.title"
        :crumbsList="crumbsList"
        @onTitleClick="onRouteChange"
        />
          <h1 class="content-title">{{ $t(`${contentDetails.title}`) }}</h1>

          <div class="content-sub-detail">
            <router-link v-if= "contentDetails.category" class="active-pointer" :to="actRedirectionMetaTag('category', contentDetails.objecttype == 'CONTENT' ? 'MOVIE' : contentDetails.objecttype)">
              {{ $t(configCTTag) || $t(contentDetails.category == 'TVSHOW' ? 'SERIES' : contentDetails.category) }}
            </router-link>
            <p v-if="contentDetails.genre">|</p>
            <router-link v-if="contentDetails.genre" class="active-pointer" :to="actRedirectionMetaTag('genre',contentDetails.defaultgenre)">
              {{ $t(`${contentDetails.genre}`) }}
            </router-link>

            <p v-if="contentDetails.subgenre && contentDetails.subgenre.length > 0 ">|</p>
            <router-link  v-if="contentDetails.subgenre && contentDetails.subgenre.length > 0 "  class="active-pointer" :to="actRedirectionMetaTag('genre',contentDetails.defaultgenre)">
              {{ $t(`${contentDetails.subgenre[0]}`) }}
            </router-link>

            <p v-if="contentDetails.contentlanguage[0] && disableLanguage">|</p>

            <router-link  v-if="contentDetails.contentlanguage[0] && disableLanguage" class="active-pointer" :to="actRedirectionMetaTag('currentLanguage', contentDetails.contentlanguage[0])" >
              {{
                contentDetails.contentlanguage[0]
                  ? $t(`${contentDetails.contentlanguage[0]}`)
                  : ""
              }}
            </router-link>

            <p v-if="contentDetails.productionyear">|</p>

            <router-link  v-if="contentDetails.productionyear" class="active-pointer" :to="actRedirectionMetaTag('productionyear', contentDetails.productionyear)">
              {{ contentDetails.productionyear }}
            </router-link >

            <p v-if="contentDetails.pgrating">|</p>

            <div dir="ltr" class="pg-rating" v-if="contentDetails.pgrating">
              <p class="camel-case">{{ contentDetails.pgrating }}</p>
            </div>
          </div>
          <div class="season-layout" v-if="contentDetails.seasoncount">
            <img
              src="@/assets/icons/season-light.svg"
              alt="items-count"
              style="margin-top:15px"
            />
            <p class="season-info">
              {{
                contentDetails.seasoncount > 1
                  ? contentDetails.seasoncount + " " + $t("Seasons")
                  : contentDetails.seasoncount + " " + $t("Season")
              }}
            </p>
          </div>
          <div
            class="content-description"
            :class="{ 'content-description-ara': localDisplayLang === 'ara' }"
            v-if="checkDescription"
          >
            <p v-if="!readMore">
              {{ getDescription }}
            </p>
            <p v-else>
              {{ getDescriptionReadMore }}
            </p>
          </div>
          <div v-if="checkDescription && checkDescriptionLength">
            <span
              v-if="!readMore"
              class="read-more"
              id="readMore"
              @click="manageReadMore()"
            >
              {{ $t("Read More") }}</span
            >
            <span
              v-else
              class="read-more"
              id="readMore"
              @click="manageReadLess()"
              >{{ $t("read less") }}</span
            >
          </div>
          <Cast-Crew v-if="contentDetails.castncrew && (contentDetails.castncrew.cast || contentDetails.castncrew.crew)" :castCrew="contentDetails.castncrew"></Cast-Crew>

          <!-- Audio and Subtitle Language Display -->
          <div v-if="getAudioAndSubtitleLangState" class="audio-subtitle">
            <div
              v-if="
                getAudioAndSubtitleLangState &&
                  getAudioAndSubtitleLangState.audiolang &&
                  getAudioAndSubtitleLangState.audiolang.length
              "
              class="audio"
            ></div>
            <div
              v-if="
                getAudioAndSubtitleLangState &&
                  getAudioAndSubtitleLangState.subtitlelang &&
                  getAudioAndSubtitleLangState.subtitlelang.length
              "
              class="subtitle"
            >
              <span class="label"> {{ $t("Subtitle") }} </span>:
              <span
                v-for="(lang,
                index) in getAudioAndSubtitleLangState.subtitlelang"
                :key="index"
                style="color: white;"
            >
                {{ $t(getLanguageFullForm(lang))
                }}<span
                  v-if="
                    index < getAudioAndSubtitleLangState.subtitlelang.length - 1
                  "
                  >,</span
                >
              </span>
            </div>
          </div>

          <!-- Quick actions -->
          <div class="content-quick-actions">
            <LikeBtn
              :content="contentDetails"
              :width="'20px'"
              :height="'20px'"
              class="hover-change"
            ></LikeBtn>
            <ToolTip>
              <template #component>
                <detailShare
                  :content="contentDetails"
                  :width="'20px'"
                  :height="'20px'"
                  class="hover-change"
                ></detailShare>
              </template>
              <template #content>
                <!-- <div class="d-flex g-10 p-5">
                  <li class="list-style-none">
                    <a
                      class="rounded-icon"
                      :href="
                        'https://www.facebook.com/share.php?u=' + shareLink
                      "
                      target="_blank"
                    >
                      <img
                        src="@/assets/icons/facebookWhite.png"
                        class="share-img"
                        alt="fb icon"
                      />
                    </a>
                  </li>

                  <li class="list-style-none">
                    <a
                      class="rounded-icon"
                      target="_blank"
                      :href="'http://twitter.com/share?url=' + shareLink"
                    >
                      <img
                        src="@/assets/icons/twitterWhite.png"
                        class="share-img"
                        alt="gp icon"
                      />
                    </a>
                  </li>

                  <li class="list-style-none" @click="copyLink">
                    <div class="rounded-icon">
                      <img
                        src="@/assets/icons/copy.png"
                        class="share-img"
                        alt="gp icon"
                      />
                    </div>
                  </li>
                </div> -->
              </template>
            </ToolTip>
            <WatchListBtn
              :content="contentDetails"
              :width="'20px'"
              :height="'20px'"
              class="hover-change"
            ></WatchListBtn>
          </div>
          <div class="content-actions">
            <div v-if="this.contentDetails.objecttype == 'CONTENT'">
              <button
                style="display: flex; justify-content: center;"
                class="button-primary"
                @click="resumeHandle()"
                v-if="contentDetails.watchedTime && getSubscriptionInformation && (contentDetails.tags && contentDetails.tags.toString().toLowerCase().includes('ct-music')) "
              >
                {{ $t("RESUME") }} 
                {{ $t("Track") }}
                <p
                  style="margin-left: 3px;"
                >
                  ({{ playerPositionPerecentage }} {{timeleft}})
                </p>
              </button>

              <button
                style="display: flex; justify-content: center;"
                class="button-primary"
                @click="resumeHandle()"
                v-else-if="contentDetails.watchedTime && getSubscriptionInformation"
              >
                {{ $t("RESUME") }}
                <p
                  style="margin-left: 3px;"
                >
                  ({{ playerPositionPerecentage }} {{timeleft}})
                </p>
              </button>

              <button
                style="display: flex; justify-content: center;"
                class="button-primary"
                @click="resumeHandle()"
                v-else-if="contentDetails.watchedTime && !getSubscriptionInformation && !(contentDetails.objecttag && contentDetails.objecttag.includes('PREMIUM'))">
                {{ $t("RESUME") }}
                <p style="margin-left: 3px;">
                  ({{ playerPositionPerecentage }} {{timeleft}})
                </p>
              </button>

              <button
                class="button-primary btn-size-fix"
              
                v-else
                @click="buttonHandler"
              >
                {{ $t(buttonStatus == "PLAY" ? "WATCH NOW" : buttonStatus) }}
              </button>


            </div>

						<div v-else>
                 <img src="@/assets/icons/loading.svg" alt="items-loading" style="width: 30px; height: 30px;margin-top: 30px;" v-show="!buttonStatus"/>
            
								  <button class="button-primary  button-status"  v-show="buttonStatus"
           				 v-if="!currentEpisode.watchedduration" @click="handleTVSeries('play')">
              			{{ $t(buttonStatus == 'PLAY' ? 'WATCH NOW'  : buttonStatus ) }}
            			</button>
                  <button style="display: flex; justify-content: center;" class="button-primary" @click="handleTVSeries('resume')" v-else-if="currentEpisode.watchedduration  && !(contentDetails.tags && contentDetails.tags.toString().toLowerCase().includes('ct-music')) " v-show="buttonStatus">
              			{{watchedEpisodeButton}} <p style="margin-left: 3px;">({{Math.floor((currentEpisode.duration - currentEpisode.watchedduration)/ 60)}}min left)</p>
          				</button>
							
                  <button style="display: flex; justify-content: center;" class="button-primary" @click="handleTVSeries('resume')" v-else>
              		  <p style="margin-left: 3px;">{{ $t("RESUME") }} {{ $t("Track") }} ({{Math.floor((currentEpisode.duration - currentEpisode.watchedduration)/ 60)}}min left)</p>
          				</button>
						</div>
        
            <button class="watch-trailer-button" @click="watchTrailer()" v-if="contentDetails.trailer">
              {{ $t("WATCH TRAILER") }}
            </button>
          </div>
            <div class="renal-validity" v-if="expireTxt"> {{ $t('rental-validity') }} <span>{{ expireTxt }}</span> </div>
        </div>
        <div class="content-image1">
          <img :src="getPoster()" class="poster" alt="Loading...." />
        </div>
      </div>
      <div class="tabs-section">
        <div class="tabs-layout">
          <div>
            <ul class="tabs-frame">
              <li
                v-for="(item, index) in tabList"
                :key="index"
                @click="selectTab(index, item)"
                :class="selectedSection === index ? activeTab : ''"
              >
                {{ $t(item) }}
              </li>
            </ul>
          </div>
        </div>
        <div class="episodes-layout">
          <!-- <keep-alive> -->
            <router-view
              :contentDetails="contentDetails"
              :playerInstance="playerInstance"
              @setCurrentEpisodeInformation="setCurrentEpisodeInformation"
              @totalEpisodeListCB="totalEpisodeListCB"
            ></router-view>
          <!-- </keep-alive> -->
        </div>
      </div>
    </div>
    <div v-if="!dataFetched">
      <detailPageLoader></detailPageLoader>
    </div>
    <div class="errorMessage" v-if="showErrorMessage">
      <p>{{ $t("No contents found") }}</p>
    </div>

    <PinModal
      id="pc-pin-detail-modal-player-config"
      :open="showPinModal"
      @onClose="closePinModal"
      :mode="pinMode"
      @onActionComplete="onPinModalActionComplete"
      :pinError="pinModalError"
      :callbackParentalLockFlag="callbackParentalLockFlag"
      :loading="pinModalLoading"
      @onForgotPin="openForgotPinModal"

      />
      <ForgotPinModal
      id="pc-forgot-pin-modal"
      :open="showForgotPinModal"
      @onClose="closeForgotPinModal"
      @onActionComplete="onForgotPinModalActionComplete"
      :pinError="forgotPinModalError"
      @onResend="resendOtp"
      :loading="loading"
    />

    <Loading v-if="isLoaderEnabled"></Loading>
    <rentPopup @closepopup=" (e) => isPopupClose = e " v-if="isPopupClose && contentDetails" :contentDetails="contentDetails" :availPlanListPopup="availPlanListPopup" :buttonStatus="buttonStatus"></rentPopup>
  </div>
</template>

<script>
import { vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";
import Utility from "@/mixins/Utility.js";
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { store } from "@/store/store";
import { showSnackBar } from "@/utilities";
import firebase from "firebase/app";
import "firebase/database";

export default {
  props: {
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      GetKidProfile:"",
      dataFetched: false,
      localDisplayLang: null,
      contentDetails: null,
      contentId: null,
      readMore: false,
      tabList: [],
      selectedSection: null,
      activeTab: "active-tab",
      activeComponent: "",
      showErrorMessage: false,
      seasonNum: 1,
      seasonCount: null,
      seasonCountArray: [],
      subscriptions: [],
      availabilities: [],
      purchases: [],
      filteredAvailabilities: [],
      pricemodel: [],
      buttonStatus: "",
      fetchStreamUrlTask: null,
      playbackInitiated: false,
      isMpegRequired: false,
      isDrmFree: true,
      progress: null,
      watchedTime: null,
      playerPositionPerecentage: null,
      currentEpisode: "",
      watchedEpisodeButton: "",
      showPinModal: false,
      pinModalError: "error message",
      pinMode: "restrict",
      testLangArr: ["Spanish", "English", "Hindi"],
      callbackParentalLockFlag: "",
      confirmDetailParentPin: null,
      isLoaderEnabled: false,
      shareLink: null,
      pinModalLoading: false,
      parentalControlsEnabled: false,
      crumbsList: [],
      disableLanguage:false,
      configCTTag: false,
      showForgotPinModal: false,
      forgotPinModalError: "",
      forgetpinloader: false,
      loading: false,
      rentNowPriceModel: {},
      isPopupClose: false,
      availPlanListPopup: {},
      rentPurchasedActive: false,
      expireTxt: '',
      userInfoDetails: [],
      timeleft:""
    };
  },
  watch: {
    $route(to, from) {
      this.contentId = to.params.contentId;
      this.selectTabUI();
      if (to.name == "detailPage" && from.name == "related") {
        eventBus.$emit("callAvailability");
      }
      if (
        (to.name == "detailPage" && from.name == "episodes") ||
        (to.name == "detailPage" && from.name == "trailer") ||
        (to.name == "detailPage" && from.name == "movieTrailer") ||
        (to.name == "detailPage" && from.name == "related") ||
        (to.name == "detailPage" && from.name == "relatedMovie")
      ) {
        location.reload();
      } else if (
        (to.name == "detailPageMovie" && from.name == "episodes") ||
        (to.name == "detailPageMovie" && from.name == "trailer") ||
        (to.name == "detailPageMovie" && from.name == "related") ||
        (to.name == "detailPageMovie" && from.name == "movieTrailer") ||
        (to.name == "detailPageMovie" && from.name == "relatedMovie")
      ) {
        location.reload();
      } else if (
        (to.name == "detailPage" && from.name == "related") ||
        (to.name == "detailPageMovie" && from.name == "relatedMovie")
      ) {
        this.selectedSection = null;
        this.tabList = [];
        this.contentDetails = null;
        this.contentId = to.params.contentId;
        location.reload();
      } 
    },
    // availabilityList(val) {
    //   if (Array.isArray(val) && val.length > 0) {
    //     this.availabilities = val;
    //     this.getContentDetail(this.contentId);
    //   }
    // },
    subscriptionList(val) {
      if (Array.isArray(val) && val.length > 0) {
        this.subscriptions = val;
      }
    },
    purchaseList (val) {
      this.purchaseListData = val;
      this.getPurchaseListCode();
    }
  },
  created() {
    this.isMpegRequired = this.checkMpegRequired();
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.localDisplayLang = this.localDisplayLang == 'ml' ? 'mal' : this.localDisplayLang;
    localStorage.setItem("paymentGetwaySaveUrl", location.href)

    try {
      this.userInfoDetails = localStorage.getItem("subscriberDetails") ? JSON.parse(localStorage.getItem("subscriberDetails")).data : [];
    } catch(err) {
      console.log(err)
    }
   

    let subscriberProfileDetails = localStorage.getItem(
      "subscriberProfileDetails"
    );
    let isenabled = localStorage.getItem("parentalControlEnabled") == 'true' ? true : false;
    this.parentalControlsEnabled = isenabled;
    if (subscriberProfileDetails) {
      subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
    }

    this.contentId = this.$route.params.contentId;
    // this.fetchAvailability();
    // console.log("availabilityList", this.availabilities);
    // if (this.availabilities.length > 0) {
    //   debugger;
    //   this.availabilities = this.availabilityList;
    //   this.getContentDetail(this.contentId);
    // }

    eventBus.$on(`contentDetail-response-${this.contentId}`, (response) => {});

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }
    if (this.purchaseList !== undefined) {
      this.purchaseListData = this.purchaseList;
      this.getPurchaseListCode()
    }
  
  },

  computed: {
    ...mapGetters([
      "appConfig",
      "subscriberid",
      "availabilityList",
      "subscriptionList",
      "purchaseList",
      "getAudioAndSubtitleLangState",
      "getAllLanguageFullForm",
      "appConfig",
      "profileid",
      "getSubscriptionInformation"
    ]),

    checkScreenWidth: function() {
      if (screen.width > 900) {
        return true;
      } else {
        return false;
      }
    },

    contentTags() {
      return this.contentDetails.tags[0].toLowerCase();
    },

    seasons: function() {
      if (
        this.contentDetails &&
        this.contentDetails.seasoncount &&
        this.contentDetails.seasoncount > 1
      ) {
        return this.contentDetails.seasoncount + " " + "Seasons";
      } else if (
        this.contentDetails &&
        this.contentDetails.seasoncount &&
        this.contentDetails.seasoncount <= 1
      ) {
        return this.contentDetails.seasoncount + " " + "Season";
      } else {
        return "";
      }
    },

    checkDescription: function() {
      if (
        (this.contentDetails && this.contentDetails.longdescription) ||
        (this.contentDetails && this.contentDetails.shortdescription)
      ) {
        return true;
      } else {
        return false;
      }
    },

    checkDescriptionLength: function() {
      if (
        (this.contentDetails &&
          this.contentDetails.longdescription &&
          this.contentDetails.longdescription.length > 250) ||
        (this.contentDetails &&
          this.contentDetails.shortdescription &&
          this.contentDetails.shortdescription.length > 250)
      ) {
        return true;
      } else {
        return false;
      }
    },

    checkMovie: function() {
      if (this.contentDetails.objecttype == 'SERIES') {
        return true;
      } else {
        return true;
      }
    },

    getDescription() {
      if ( this.contentDetails.longdescription && this.contentDetails.longdescription.length > 250) {
        return this.contentDetails.longdescription.slice(0, 250);
      } else if (
        this.contentDetails.shortdescription &&
        this.contentDetails.shortdescription.length > 250
      ) {
        return this.contentDetails.shortdescription.slice(0, 250);
      }  else if (this.contentDetails.shortdescription) {
        return this.contentDetails.shortdescription;
      } else if (this.contentDetails.longdescription) {
        return this.contentDetails.longdescription;
      }
    },

    getDescriptionReadMore() {
      if (this.contentDetails.longdescription) {
        return this.contentDetails.longdescription;
      } else if (this.contentDetails.shortdescription) {
        return this.contentDetails.shortdescription;
      }
    },
  },
  mounted() {
    this.contentId = this.$route.params.contentId;
    this.fetchAvailability();
    this.createLink("sharing");
    this.timeleft = this.$t("minutesLeft")
    this.GetKidProfile = localStorage.getItem("isKidsMode");

    // this.getContentProgress();
    this.getEpisodeContentProgress();
    this.setAudioAndSubtitleLangState(null);

    eventBus.$on("changeLanguageView", () => {
      location.reload();
    });
    this.disableLanguage = window.PROVIDER_SETUP_CONFIG.isActiveDetailLanguage
    setTimeout(() => {
      if (
        this.$route.fullPath.includes("play") &&
        this.$route.fullPath.includes("trailer")
      ) {
        this.watchTrailer();
      } else if (this.$route.fullPath.includes("play")) {
        if (this.subscriberid) {
          this.buttonHandler("movie");
        } else {
          let payload = {
            state: true,
            formType: "lookup",
          };
          eventBus.$emit("authPopup", payload);
        }
      }
    }, 2000);

    eventBus.$on("player-visibility", (isVisible) => {
      if (isVisible == false && this.playbackInitiated == true) {
        this.playbackInitiated = false;
        this.playerInstance.deregisterEvent("currentcontentended");
      }
    });
  },
  components: {
    detailPageLoader: () =>
      import(
        /* webpackChunkName: "detailPageLoader" */ "@/components/DetailPage/detailPageLoader.vue"
      ),
      rentPopup: () =>  import(
        /* webpackChunkName: "rentPopup" */ "@/components/Popups/Payment/RentPaymentModal.vue"
      ),
      "Cast-Crew": () => import(
        /* webpackChunkName: "detailPageLoader" */ "@/components/DetailPage/CastCrew.vue"
      ),
    LikeBtn: () =>
      import(
        /* webpackChunkName: "likebutton" */ "@/components/SvgImages/LikeBtn.vue"
      ),
    WatchListBtn: () =>
      import(
        /* webpackChunkName: "watchlistbtn" */ "@/components/watchlist/watchListBtn.vue"
      ),
    detailShare: () =>
      import(
        /* webpackChunkName: "detailshare" */ "@/components/SvgImages/detailIcon.vue"
      ),
    PinModal: () => import(
     /* webpackChunkName: "PinModal" */  "@/components/utils/PinModal.vue"
      ),
    BreadCrumbs: () => import(
       /* webpackChunkName: "BreadCrumbs" */  "@/components/utils/BreadCrumbs.vue"
    ),
    ToolTip: () => import(
       /* webpackChunkName: "ToolTip" */ "@/components/utils/ToolTip.vue"
       ),
    Loading: () =>
      import(
        /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"
      ),
      ForgotPinModal: () => import("@/components/utils/ForgotPinModal.vue"),
  },
  methods: {
    ...mapMutations(["setRegActiveDeeplink", "setAudioAndSubtitleLangState"]),
    ...mapActions([
      "getLanguagesByCodes",
      "parentalControlVerifyOldPin",
      "authorizeCurrentStreamApi",
      "setCurrentStreamApi",
      "parentalControlPinSet",
       "parentalControlResetPin",
       "availabilityDetail"
    ]),
    fetchAvailability() {
      let page = 1;
      let pageSize = 15;
      let payload = {
        page: page,
        pagesize: pageSize,
      };
      store
        .dispatch("availabilityList", payload)
        .then((response) => {
          this.availabilities = response.data.data;
          this.getContentDetail(this.contentId);
        })
    },

    getFormatedDate(d) {

      function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      }

      try {
          let newDate = new Date(d);

          newDate.setHours(newDate.getHours() + 5);
          newDate.setMinutes(newDate.getMinutes() + 30);

          return newDate.getDate() + " " + newDate.toLocaleString('default', {
              month: 'short'
          }) + " " + newDate.getFullYear() + ", " + formatAMPM(newDate);
      } catch (e) {
          return d;
      }

    },
    getPurchaseListCode () {
      for(let list of this.purchaseListData) { 

       if ( (list.objectid).toLowerCase() == this.contentId) {
          this.rentPurchasedActive = true;
          this.expireTxt = this.getFormatedDate(list.expiry);
          
       }  
      }
    },
    resendOtp() {
      //do something here. (Make call to the API)
      this.loading = true;
      let payload = {
        otp_type: "parentalControlPinUpdate",
      };
      //do something here. (Make call to the API)
      //received otp to email
      this.parentalControlPinSet(payload)
        .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            this.pinModalError = this.$t(data.data.reason);
            return;
          } else if (data.data.success) {
            console.log("parentalControlPinSet", data);
          }
        })
        .catch((error) => {
          console.log("parentalControlPinSet error -> ", error);
        });
    },
    closeForgotPinModal() {
      this.showForgotPinModal = false;
    },
    onForgotPinModalActionComplete(data) {
      // forget pin reset api call
      this.loading = true;
      let forgetPinPayload = {
        pin: data.pin,
        OTP: data.otp,
      };
      this.parentalControlResetPin(forgetPinPayload)
        .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            this.forgotPinModalError = this.$t(data.data.reason);
            return;
          } else if (data.data.success) {
            this.showForgotPinModal = false;
          }
          console.log("Resret Pin Response", data);
        })
        .catch((error) => {
          console.log("parentalControlResetPin error -> ", error);
        });
    },
  openForgotPinModal() {
  const isKidsMode = localStorage.getItem("isKidsMode") === "true";
  if (isKidsMode) {
  this.pinModalError = this.$t("Unauthorized access");
  // return;
  }  
  this.showForgotPinModal = true;
  this.loading = true;
  

  let payload = {
    otp_type: "parentalControlPinUpdate",
  };

  this.parentalControlPinSet(payload)
    .then((data) => {
      this.loading = false;
      if (data.data.reason) {
        this.pinModalError = this.$t(data.data.reason);
      } else if (data.data.success) {
        console.log("parentalControlPinSet", data);
      }
    })
    .catch((error) => {
      console.log("parentalControlPinSet error -> ", error);
    });
},

    actRedirectionMetaTag(type, tag){
      let section = (tag.toString()).toLowerCase()
      let currentLanguage = this.getCurrentLanguageUrlBase();
      return({ name: "screen-meta-redirection", params: { objecttype: (this.contentDetails.objecttype).toLowerCase(), value: section , type: type, lang: currentLanguage } });
    },

    onRouteChange(clickedObj){
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if(clickedObj.title === "Home"){
        this.$router.push({name: clickedObj.title, params: {lang: currentLanguage }});
        return;
      }
      if(clickedObj.reference === "CONTENT"){
        this.$router.push({
            name: "section-screen",
            params: { section: "movies", lang: currentLanguage },
          });
      }
      else if(clickedObj.reference === "SERIES" || clickedObj.reference.toUpperCase() === "NATAK"){
        this.$router.push({
            name: "section-screen",
            params: { section: "shows", lang: currentLanguage },
          });
      }  else if(clickedObj.reference.toUpperCase() === "MUSIC"){
          this.$router.push({
            name: "section-screen-video",
            params: { section: 'music', lang: currentLanguage },
          });
      } else {
      
        this.$router.push({name: "Home", params: {lang: currentLanguage }});
      }
    },
    openPinModal() {
      this.showPinModal = true;
    },
    totalEpisodeListCB(val) {
      if (this.availabilities.length == 0) {
        this.availabilities = this.availabilityList;
      }

      this.getAvailability(val);
    },
    getUpdatedTag() {
      if (!this.contentDetails.tags) return;

      for (let tag of this.contentDetails.tags) {
        if (tag.includes('CT-')) {
          this.configCTTag = tag.split('CT-')[1];
          if (this.appConfig.contentTypeTags) {
            let language = 'en'
            for (let lang of window.PROVIDER_SETUP_CONFIG.supportLanguageList) {
              if (lang.includes(localStorage.getItem('setDisplayLanguageCode'))) {
                language = lang;
              }
               
            }
            this.configCTTag = this.appConfig.contentTypeTags[language][this.configCTTag]  || this.configCTTag;
            break;
          }
        }
      }
    },
    contentRenderSetup(response) {
      if (!response.reason) {
        this.dataFetched = true;
        this.contentDetails = response.data;
        this.getContentProgress(response.data)

        this.getUpdatedTag();
        let subscriberProfileDetails = localStorage.getItem(
          "subscriberProfileDetails"
        );
        if (subscriberProfileDetails) {
          subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
        }

        if (this.contentDetails.objecttype == 'CONTENT') {
          if (this.availabilities.length == 0) {
            this.availabilities = this.availabilityList;
          }
          this.getAvailability(this.contentDetails);
        }

        this.playerPositionPerecentage = this.contentDetails
          ? this.contentDetails.duration - this.contentDetails.watchedTime
          : this.contentDetails.duration - this.contentDetails.watchedTime;
        this.playerPositionPerecentage = Math.floor(
          this.playerPositionPerecentage / 60
        );
   
        let externalDataSet = response.data && response.data.metacontent && response.data.metacontent.content ?  response.data.metacontent.content : "";
        if(externalDataSet) {
          try {
            externalDataSet = JSON.parse(externalDataSet);            
          } catch (error) {
            externalDataSet = {}
          }
        } else {
          externalDataSet = {}
        }
        let metaTitle = externalDataSet.title ?  externalDataSet.title : "Watch " + (response.data.defaulttitle || response.data.title) + " on " + "Saina Play";
        let metaDescription = externalDataSet.description ? externalDataSet.description : response.data.shortdescription;
        let meatKeywords = externalDataSet.keywords ? externalDataSet.keywords : "";
        let metaOgTitle = externalDataSet['og:title'] ? externalDataSet['og:title'] : metaTitle;
        let metaTwitterTitle = externalDataSet['twitter:title'] ? externalDataSet['twitter:title'] : metaTitle;
        let metaOgDescription = externalDataSet['og:description'] ? externalDataSet['og:description'] : metaDescription;
        let metaTwitterDescription = externalDataSet['twitter:description'] ? externalDataSet['twitter:description'] : metaDescription;
        window.document.title = metaTitle;
        document.getElementsByTagName('meta')["keywords"].setAttribute('content', meatKeywords);
        document.getElementsByTagName('meta')["description"].setAttribute('content', metaDescription);
        document.querySelector('meta[property="og\\:title"]').setAttribute('content', metaOgTitle);
        document.querySelector('meta[name="twitter\\:title"]').setAttribute('content', metaTwitterTitle);
        document.querySelector('meta[property="og\\:description"]').setAttribute('content', metaOgDescription);
        document.querySelector('meta[name="twitter\\:description"]').setAttribute('content', metaTwitterDescription);
        let poster = this.getPoster()
        document.querySelector('meta[property="og\\:image"]').setAttribute('content', poster);
   

        this.seasonNum = this.contentDetails.seasoncount;
        this.fetchTabList();
        this.selectTab(0);

        // if (
        //   subscriberProfileDetails &&
        //   subscriberProfileDetails.kidsmode === "YES" &&
        //   this.contentDetails.defaultgenre !== "KIDS"
        // ) {
        //   showSnackBar(this.$t("Content is not accessible"));
        //   this.$router.replace("/");
        // }

        let crumbList = [];
        let home = {title: this.$t("Home"), reference: "home"}
        crumbList.push(home);
        if(this.contentDetails.category){
          let category = {title: this.$t( this.configCTTag ? this.configCTTag : this.contentDetails.category == 'TVSHOW' ? 'SERIES' : this.contentDetails.category), reference: this.configCTTag ? this.configCTTag : this.contentDetails.objecttype};

          crumbList.push(category);
        }
        this.crumbsList = crumbList;

        this.scrollTop();


      } else if (reason.response) {
        this.dataFetched = true;
        this.showErrorMessage = true;
      }
    },
    closePinModal() {
      this.showPinModal = false;
      this.callbackParentalLockFlag = "";
    },
    onPinModalActionComplete(enteredPin) {
      this.isLoaderEnabled = true;
      this.pinModalLoading = true;

      this.confirmDetailParentPin = enteredPin;
      let payload = {
        pin: this.confirmDetailParentPin,
      };
      this.parentalControlVerifyOldPin(payload)
        .then((data) => {
          this.pinModalLoading = false;
          if (data.data.reason) {
            this.pinModalError = this.$t(data.data.reason);
            return;
          } else if (data.data.success) {
            //this.showPinModal = false;
            this.isLoaderEnabled = false;

            if (this.callbackParentalLockFlag == "MOVIE-RESUME") {
              this.resumeHandle(true);
            } else if (this.callbackParentalLockFlag == "MOVIE-PLAY") {
              this.buttonHandler(false, true);
            } else if (this.callbackParentalLockFlag == "SERIES-RESUME") {
              this.handleTVSeries(true, true);
            } else if (this.callbackParentalLockFlag == "SERIES-PLAY") {
              this.handleTVSeries(false, true);
            }

            this.closePinModal();
          }
        })
        .catch((error) => {
          console.log("parentalControlVerifyOldPin error -> ", error);
        });
    },
    setCurrentEpisodeInformation(val) {
      this.currentEpisode = val;
      this.watchedEpisodeButton =
        this.$t("RESUME") +
        " " +
        "S" +
        this.currentEpisode.seasonnum +
        ":E" +
        this.currentEpisode.episodenum;

      if (this.availabilities.length == 0) {
        this.availabilities = this.availabilityList;
      }

        // this.contentDetails.watchedTime = val.watchedduration

      this.getAvailability(this.currentEpisode);
    },
    handleTVSeries(e, status = false) {
      let activeRent = false;
      for(let list of this.purchaseListData) { 
        if ( (list.objectid).toLowerCase() == (this.currentEpisode.objectid).toLowerCase() ) {
          activeRent = true;
        }  
      }

      if (!activeRent && this.subscriberid && (this.buttonStatus == "RENT NOW" || this.buttonStatus == "PURCHASE NOW")) { 


       if (this.subscriberid !== this.profileid) {
          eventBus.$emit("showAuthorizeErrorMessage", {
          state: true,
          messageData: {
            title: this.$t("Error"),
            message: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin")
          },
        });
        return;
      }
      eventBus.$emit("open-home-rzp-modal", this.rentNowPriceModel);
      return;
    }
     else if (
        !this.subscriberid &&
        (this.buttonStatus === "PLAY" || this.buttonStatus === "LOGIN & WATCH")
      ) {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      } else if (
        this.subscriberid &&
        this.buttonStatus === "SUBSCRIBE & WATCH" &&
        this.subscriberid !== this.profileid
      ) {
        eventBus.$emit("showAuthorizeErrorMessage", {
          state: true,
          messageData: {
            title: "Error",
            message: this.$t(
              "Sorry! Admin only allowed to the payment. Please contact your Admin"
            ),
          },
        });
      }

      // For master Profile
      else if (this.subscriberid && this.buttonStatus === "SUBSCRIBE & WATCH") {
        console.log("THIS IS THE SUBSCRIBE POPUP");
        this.setRegActiveDeeplink(true);
        //show subscribe popup.
        // let payload = { listType: "onlySubscribe" };
        // eventBus.$emit("subscribePopup", payload);
        localStorage.setItem("paymentGetwaySaveUrl", location.href)

        let currentLanguage = this.getCurrentLanguageUrlBase();
        this.$router.push({name: 'seeAllPlans', params: {lang: currentLanguage}});
      }

      if (e == "resume") {
        this.showPinModal = false;
        if (
          !this.isContentPlayableParentalLock(
            this.getCurrentProfile(),
            this.contentDetails.pgrating
          ) &&
          status 
        ) {
          if(this.parentalControlsEnabled){
            this.callbackParentalLockFlag = "SERIES-RESUME";
          this.showPinModal = true;
          return;
          }
         
        }

        eventBus.$emit("cbPlayerCallCounter", "resume");
      } else {
        if (
          !this.isContentPlayableParentalLock(
            this.getCurrentProfile(),
            this.contentDetails.pgrating
          ) &&
          !status
        ) {

          if(this.parentalControlsEnabled){
            this.callbackParentalLockFlag = "SERIES-PLAY";
          this.showPinModal = true;
          return;
          }
        
        }
        eventBus.$emit("cbPlayerCallCounter", "play");
      }
    },
    getContentProgress(content) {
      // let content = this.content;
      let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/${content.objectid}`;

      firebase
        .database()
        .ref(path)
        .on("value", (snapshot) => {
          let response = snapshot.val();
          if (content && response) {
            if (response.status === "INPROGRESS") {
              if (response.objecttype === "CONTENT") {
                let duration = response.duration;
                let watched = response.watchedduration;
                this.watchedTime = response.watchedduration;
                
                this.contentDetails.watchedTime = watched

                this.playerPositionPerecentage = (duration - watched);

				        this.playerPositionPerecentage =Math.floor(this.playerPositionPerecentage / 60);

                let progress = (watched / duration) * 100;
                if (progress > 0 && progress < 100) {
                  this.progress = progress;
                }
              }
            }
          }
        });
    },
    getEpisodeContentProgress() {
      let content = this.contentDetails;
      if (content.status === "INPROGRESS") {
        let duration = content.duration;
        let watched = content.watchedduration;
        this.watchedTime = content.watchedduration;
        let progress = (watched / duration) * 100;
        if (progress > 0 && progress < 100) {
          this.progress = progress;
        }
      }
    },

    getLanguageFullForm(lang) {
      let fullForm = "";
      if (lang) {
        this.getAllLanguageFullForm.forEach((langObj) => {
          if (langObj["alpha3-b"] === lang) {
            fullForm = langObj.English;
            return;
          }
        });
      }

      return fullForm;
    },

    pickTagForContent(content) {
      if (content.hasOwnProperty("objecttag")) {
        let tag = content.objecttag[0];
        let contenttags = this.appConfig.contentTags;
        if (this.localDisplayLang === "mal") {
          return contenttags.ml[`${tag}`];
        } else {
          return contenttags.en[`${tag}`];
        }
      }
    },
    isObjectTag(content) {
      if (content.hasOwnProperty("objecttag")) {
        if (Array.isArray(content.objecttag)) {
          return content.objecttag.length === 0 ? false : true;
        } else {
          return content.objecttag === null ? false : true;
        }
      }
    },
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    buttonHandler(isDeeplink = false, status = false) {
      let fullPath = this.$route.fullPath.split("/");
      if (isDeeplink == "movie") {
        window.history.pushState(
          "object or string",
          fullPath[fullPath.length - 1],
          "play"
        );
      }
      if (
        !this.subscriberid &&
        (this.buttonStatus === "PLAY" || this.buttonStatus === "LOGIN & WATCH" || this.buttonStatus === "SUBSCRIBE & WATCH")
      ) {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      } else if (
        this.subscriberid &&
        this.buttonStatus === "SUBSCRIBE & WATCH" &&
        this.subscriberid !== this.profileid
      ) {
        eventBus.$emit("showAuthorizeErrorMessage", {
          state: true,
          messageData: {
            title: "Error",
            message: this.$t(
              "Sorry! Admin only allowed to the payment. Please contact your Admin"
            ),
          },
        });
      }

      // For master Profile
      else if (this.subscriberid && this.buttonStatus === "SUBSCRIBE & WATCH") {
        console.log("THIS IS THE SUBSCRIBE POPUP");
        this.setRegActiveDeeplink(true);
        //show subscribe popup.
        // let payload = { listType: "onlySubscribe" };
        // eventBus.$emit("subscribePopup", payload);
        localStorage.setItem("paymentGetwaySaveUrl", location.href)

        let currentLanguage = this.getCurrentLanguageUrlBase();
        this.$router.push({name: 'seeAllPlans', params: {lang: currentLanguage}});

      } else if (this.subscriberid && (this.buttonStatus === "PLAY" || this.buttonStatus === "WATCH NOW")) {
        if (
          !this.isContentPlayableParentalLock(
            this.getCurrentProfile(),
            this.contentDetails.pgrating
          ) &&
          !status
        ) {
          if(this.parentalControlsEnabled){
            this.callbackParentalLockFlag = "MOVIE-PLAY";
          this.showPinModal = true;
          return;
          }
          
        }

        this.showPlayer();

        let contentList = [this.contentDetails];
        console.log("Content list : ", contentList);
        this.loadChosenIndexContent(0, contentList, contentList.length, true);

        // this.closePopup();
      } 
      else if (this.subscriberid && (this.buttonStatus == "RENT NOW" || this.buttonStatus == "PURCHASE NOW")) { 
        if (this.subscriberid !== this.profileid) {
            eventBus.$emit("showAuthorizeErrorMessage", {
            state: true,
            messageData: {
            title: this.$t("Error"),
            message: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin")
          },
        });
        return;
    } else {
      eventBus.$emit("open-home-rzp-modal", this.rentNowPriceModel);
    }
      }
       else if ((this.buttonStatus === "SUBSCRIBE OR PURCHASE" || this.buttonStatus === "SUBSCRIBE OR RENT")) {
        if (this.subscriberid !== this.profileid) {
        eventBus.$emit("showAuthorizeErrorMessage", {
        state: true,
        messageData: {
        title: this.$t("Error"),
        message: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin")
      },
    });
    return;
  }
        this.isPopupClose = true;
      }
    },
    resumeHandle(status = false) {
      this.showPinModal = false;
      if (
        !this.isContentPlayableParentalLock(
          this.getCurrentProfile(),
          this.contentDetails.pgrating
        ) &&
        !status 
      ) {
        if(this.parentalControlsEnabled){
          this.callbackParentalLockFlag = "MOVIE-RESUME";
        this.showPinModal = true;
        return;
          }
     
      }

      this.showPlayer();
      let contentList = [this.contentDetails];
      this.loadChosenIndexContent(0, contentList, contentList.length, true);
    },
    cardClickHandler(content) {
      if (content.objecttype == "CONTENT" && this.isDirectPlay) {
        if (
          this.$router.currentRoute.params &&
          this.$router.currentRoute.params.section
        ) {
          localStorage.setItem(
            "playback_source",
            this.$router.currentRoute.params.section
          );
        } else {
          localStorage.setItem("playback_source", "HOME");
        }

        this.playContent(content);
      } else {
        let currentLanguage = this.getCurrentLanguageUrlBase();

        if (screen.width > 0) {
          if (content.category == "TVSHOW") {
            let title = this.spacialCharEncoding(content.defaulttitle);
            this.$router.push({
              name: "detailPage",
              params: {
                contentId: content.objectid,
                mediaTitle: title,
                lang: currentLanguage,
              },
            });
          } else {
            let title = this.spacialCharEncoding(content.defaulttitle);
            this.$router.push({
              name: "detailPageMovie",
              params: {
                mediaTitle: title,
                contentId: content.objectid,
                lang: currentLanguage,
              },
            });
          }
        }
      }
    },
    loadChosenIndexContent(
      episodeIndex,
      episodeList,
      totalCount,
      isFirstTime = true
    ) {
      this.fetchContentStreamUrl(episodeList[episodeIndex], this.isMpegRequired)
        .then((resp) => {
          let loadPlayer = false;

          if (this.appConfig.featureEnabled.isConcurrencyEnabled) {
            // Call the Stream Authorize API
            this.authorizeCurrentStreamApi().then((authResponse) => {
              if (!authResponse.data.errorcode) {
                if (typeof resp == "object" && !resp.data.errorcode) {
                  this.handleFetchContentStreamUrl(
                    resp,
                    episodeList[episodeIndex]
                  );
                  loadPlayer = true;
                } else if (typeof resp == "boolean" && resp == true) {
                  loadPlayer = true;
                }
                if (loadPlayer == true) {
                  this.loadContentMetaData(episodeList[episodeIndex], null);

                  let playlist = this.buildPlayerPlaylist(
                    episodeList,
                    false,
                    0
                  );
                  this.playerInstance.loadPlaylist(playlist, totalCount);

                  this.playerInstance.loadContent(episodeIndex).then(() => {
                    this.playerInstance.play().then(() => {
                      const payload = {
                        contentId: episodeList[episodeIndex].objectid,
                        params: {
                          devicetype: "PC",
                        },
                      };

                      this.setCurrentStreamApi(payload).then(
                        (setStreamResponse) => {
                          if (setStreamResponse.data.errorcode) {
                            this.hidePlayer();

                            eventBus.$emit("showAuthorizeErrorMessage", {
                              state: true,
                              messageData: {
                                title: this.$t("Error"),
                                message: this.$t("Device Limit Reached"),
                              },
                            });
                          }
                        }
                      );
                    });
                  });
                } else {
                  let playbackError = true;

                  if (typeof resp == "object") {
                    playbackError = resp.data;
                  }

                  this.hidePlayer();

                  eventBus.$emit("showAuthorizeErrorMessage", {
                    state: true,
                    messageData: {
                      title: this.$t("Error"),
                      message: this.$t("Device Limit Reached"),
                    },
                  });

                  throw playbackError;
                }
              } else {
                let playbackError = true;

                if (typeof resp == "object") {
                  playbackError = resp.data;
                }

                this.hidePlayer();

                eventBus.$emit("showAuthorizeErrorMessage", {
                  state: true,
                  messageData: {
                    title: this.$t("Error"),
                    message: this.$t("Device Limit Reached"),
                  },
                });

                throw playbackError;
              }
            });
          }

          // Else if Concurrency is not enabled
          else {
            if (typeof resp == "object" && !resp.data.errorcode) {
              this.handleFetchContentStreamUrl(resp, episodeList[episodeIndex]);
              loadPlayer = true;
            } else if (typeof resp == "boolean" && resp == true) {
              loadPlayer = true;
            }
            if (loadPlayer == true) {
              this.loadContentMetaData(episodeList[episodeIndex], null);

              let playlist = this.buildPlayerPlaylist(episodeList, false, 0);
              this.playerInstance.loadPlaylist(playlist, totalCount);

              this.playerInstance.loadContent(episodeIndex).then(() => {
                this.playerInstance.play().then(() => {
                  console.log("Playback successful");
                });
              });
            } else {
              let playbackError = true;

              if (typeof resp == "object") {
                playbackError = resp.data;
              }

              this.hidePlayer();

              throw playbackError;
            }
          }
        })
        .catch((error) => {
          this.hidePlayer();
        });
    },
    getAvailability(contentItem) {
      if (contentItem) {
        this.isButtonEnable = true;
        let packageDetails = this.getFilteredPackage(contentItem);
        const langPayload = {
          audiolang: packageDetails && packageDetails.audiolang,
          subtitlelang: packageDetails && packageDetails.subtitlelang,
        };

        this.setAudioAndSubtitleLangState(langPayload);
        let availPlanList = this.getAvailabilityAndPlanDetails(
          packageDetails,
          this.availabilities
        );
        let pricemodel = availPlanList.pricemodel;
        //based on pricemodel of availabilities assigning buttonstatus.


        let filteredAvailabilityList = availPlanList.filteredAvailabilities;

        filteredAvailabilityList.every((availability) => {
          if (availability.pricemodel === "PLAN") {
            if (this.subscriberid && this.subscriptions.length > 0) {
              this.subscriptions.every((subscription) => {
                // Check if next billing date is greater than today's date.
                if (
                  this.getDateForPlayback(subscription.nextbilling) <
                  this.getTodaysDate()
                )
                  return;

                let value = subscription.availabilityset.includes(
                  availability.availabilityid
                );

                if (value) {
                  contentItem.isDrmContent = this.isDrmFree ? true : false;
                  this.isButtonEnable = true;
                  this.buttonStatus = "PLAY";
                  contentItem.videoType = "Content";
                  contentItem.playback_details = {
                    packageid: packageDetails.packageid,
                    availabilityid: availability.availabilityid,
                    drmscheme: packageDetails.drmscheme[0],
                  };

                  return false;
                }
              });
            } else if (this.subscriberid && this.subscriptions.length === 0) {
              this.buttonStatus = "SUBSCRIBE & WATCH";
            } else if (!this.subscriberid) {
              this.buttonStatus = "LOGIN & WATCH";
            }
          } else if (this.subscriberid && availability.pricemodel == "FREE") {
            contentItem.isDrmContent = this.isDrmFree ? true : false;
            this.isButtonEnable = true;
            this.buttonStatus = "PLAY";
            contentItem.videoType = "Content";
            //creating this playback_details for content playback.
            contentItem.playback_details = {
              packageid: packageDetails.packageid,
              availabilityid: availability.availabilityid,
              drmscheme: packageDetails.drmscheme[0],
            };
            return false;
          } else if (this.subscriberid && (availability.pricemodel == "RENTAL" || availability.pricemodel == "PAID")) {
            if (this.rentPurchasedActive) {

              contentItem.isDrmContent = this.isDrmFree ? true : false;
              this.isButtonEnable = true;
              let selectedAvailability = '';
              contentItem.videoType = "Content";
              filteredAvailabilityList.forEach((availabilityNew) => {
              if (availabilityNew.pricemodel === availability.pricemodel ) {
                selectedAvailability = availabilityNew.availabilityid;
              }
            });
              contentItem.playback_details = {
                packageid: packageDetails.packageid,
                availabilityid: selectedAvailability,
                drmscheme: packageDetails.drmscheme[0],
              };

              this.buttonStatus = "WATCH NOW"
              return;
            }

            this.availabilityDetail(availability.availabilityid).then((res) => {
              let userCountryCurrency = this.userInfoDetails.subscribercountry == 'IN' ? 'INR' : 'USD';
              let currentPriceItem = {};
                for (let Item of res.data.priceclassdetail)  {
                    if (Item.currency == userCountryCurrency) {
                      currentPriceItem = Item;
                    }
                }

              this.rentNowPriceModel = {};
              this.rentNowPriceModel.planname = this.contentDetails.title;
              this.rentNowPriceModel.amount =  currentPriceItem.price;
              this.rentNowPriceModel.planid = currentPriceItem.priceclassid;
              this.rentNowPriceModel.priceclassid = currentPriceItem.priceclassid;
              this.rentNowPriceModel.availabilityid = availability.availabilityid;
              this.rentNowPriceModel.planinterval = res.data.licenseduration;
              this.rentNowPriceModel.currency = currentPriceItem.currency;
              this.rentNowPriceModel.rental = "PURCHASE";
              this.rentNowPriceModel.getPoster = this.getPoster();
              this.rentNowPriceModel.objectid  = this.contentDetails.objectid

              if (!(this.buttonStatus.includes('RENT') || this.buttonStatus.includes('PURCHASE') || this.buttonStatus.includes('WATCH NOW'))) {
                this.buttonStatus = availability.pricemodel == "RENTAL" ? "RENT NOW" : "PURCHASE NOW";
              } 
             

              
            })
          } else {
            this.buttonStatus = "LOGIN & WATCH";
          }
        });



        if ( this.subscriberid && pricemodel.includes("PLAN") && pricemodel.includes("PAID")) {
          if (this.rentPurchasedActive || this.subscriptions.length > 0 ) {

            contentItem.isDrmContent = this.isDrmFree ? true : false;
            this.isButtonEnable = true;
            let selectedAvailability = '';
            contentItem.videoType = "Content";
            filteredAvailabilityList.forEach((availability) => {
              if (availability.pricemodel === (this.rentPurchasedActive ? "PAID" : "PLAN") ) {
                selectedAvailability = availability.availabilityid;
              }
            });

            contentItem.playback_details = {
              packageid: packageDetails.packageid,
              availabilityid: selectedAvailability,
              drmscheme: packageDetails.drmscheme[0],
            };

              this.buttonStatus = "WATCH NOW"
              return;
            }
          this.buttonStatus = "SUBSCRIBE OR PURCHASE"
          this.availPlanListPopup = availPlanList
          return;
        } else if ( this.subscriberid && pricemodel.includes("PLAN") && pricemodel.includes("RENTAL")) {
          if (this.rentPurchasedActive || this.subscriptions.length > 0 ) {

            contentItem.isDrmContent = this.isDrmFree ? true : false;
            this.isButtonEnable = true;
            let selectedAvailability = '';
            contentItem.videoType = "Content";
            filteredAvailabilityList.forEach((availability) => {
              if (availability.pricemodel === (this.rentPurchasedActive ? "RENTAL" : "PLAN")) {
                selectedAvailability = availability.availabilityid;
              }
            });

            contentItem.playback_details = {
              packageid: packageDetails.packageid,
              availabilityid: selectedAvailability,
              drmscheme: packageDetails.drmscheme[0],
            };

              this.buttonStatus = "WATCH NOW"
              return;
            }
          this.buttonStatus = "SUBSCRIBE OR RENT"
          this.availPlanListPopup = availPlanList
          return;
        } else if ( this.subscriberid && pricemodel.includes("PLAN") && pricemodel.includes("PAID")) {
          if (this.rentPurchasedActive || this.subscriptions.length > 0 ) {

            contentItem.isDrmContent = this.isDrmFree ? true : false;
            this.isButtonEnable = true;
            let selectedAvailability = '';
            contentItem.videoType = "Content";
            filteredAvailabilityList.forEach((availability) => {
              if (availability.pricemodel === (this.rentPurchasedActive ? "PAID" : "PLAN")) {
                selectedAvailability = availability.availabilityid;
              }
            });

            contentItem.playback_details = {
              packageid: packageDetails.packageid,
              availabilityid: selectedAvailability,
              drmscheme: packageDetails.drmscheme[0],
            };

              this.buttonStatus = "WATCH NOW"
              return;
            }
          this.buttonStatus = "SUBSCRIBE OR RENT"
          this.availPlanListPopup = availPlanList
          return;
        } else if ( this.subscriberid && this.subscriptions.length === 0 && pricemodel.includes("PLAN")) {
          this.buttonStatus = "SUBSCRIBE & WATCH";
        } else if ( this.subscriberid && this.subscriptions.length > 0 && pricemodel.includes("PLAN")) {
          this.buttonStatus = "WATCH NOW";
        } else if ( this.subscriberid && pricemodel.includes("FREE")) { 
          this.buttonStatus = "WATCH NOW";
        } 
        
      } else {
        this.buttonStatus = "WATCH NOW";
      }
    },
    getAvailabilityAndPlanDetails(packageDetails, availabilitiesList) {
      let priceAndAvailabilityData = {};
      priceAndAvailabilityData.pricemodel = [];
      priceAndAvailabilityData.filteredAvailabilities = [];
      if (!packageDetails) return;
      packageDetails.availabilityset.forEach((availability) => {
        let availabilityIndex = availabilitiesList.findIndex((element) => {
          return element.availabilityid === availability;
        });

        if (availabilityIndex > -1) {
          priceAndAvailabilityData.pricemodel.push(
            availabilitiesList[availabilityIndex].pricemodel
          );
          priceAndAvailabilityData.filteredAvailabilities.push(
            availabilitiesList[availabilityIndex]
          );
        }
      });

      return priceAndAvailabilityData;
    },
    getFilteredPackage(contentItem) {
      let temporaryVideoTag = document.createElement("video");
      let hasNativeHlsSupport = temporaryVideoTag.canPlayType(
        "application/vnd.apple.mpegurl"
      );

      let isAndroid = navigator.userAgent.includes("Android");

      let packageList = [];
      //pick content details if that availability id is there in availability list.
      this.availabilities.forEach((availability) => {
        contentItem &&
          contentItem.contentdetails &&
          contentItem.contentdetails.forEach((element) => {
            if (element.availabilityset.includes(availability.availabilityid)) {
              packageList.push(element);
            }
          });
      });
      let packageIndex = packageList.findIndex((contentPackage) => {
        if (!isAndroid && hasNativeHlsSupport != "") {
          return contentPackage.drmscheme[0] === "FAIRPLAY";
        } else {
          return contentPackage.drmscheme[0] === "WIDEVINE";
        }
      });

      if (packageIndex == -1) {
        return packageList[
          packageList.findIndex((contentPackage) => {
            if (
              contentPackage.streamtype === "HLS" &&
              !isAndroid &&
              hasNativeHlsSupport != ""
            ) {
              this.isDrmFree = false;
              return contentPackage.drmscheme[0] === "NONE";
            } else if (
              contentPackage.streamtype === "DASH" &&
              !(!isAndroid && hasNativeHlsSupport != "")
            ) {
              this.isDrmFree = false;
              return contentPackage.drmscheme[0] === "NONE";
            }
          })
        ];
      } else if (packageIndex > -1) {
        return packageList[packageIndex];
      }
    },
    buildContentStreamRequest(contentItem, isMpegRequired) {
      let payload = {
        contentid: contentItem.objectid,
        params: {
          availabilityid: contentItem.playback_details.availabilityid,
          packageid: contentItem.playback_details.packageid,
        },
      };

      if (isMpegRequired == true) {
        payload.params.mpegtspackage = "YES";
      }

      return payload;
    },

    fetchContentStreamUrl(contentItem, isMpegRequired) {
      return new Promise((resolve, reject) => {
        if (contentItem.streamUrl != undefined && contentItem.streamUrl != "") {
          resolve(true);
        } else {
          resolve(
            store.dispatch(
              "contentStreamUrl",
              this.buildContentStreamRequest(contentItem, isMpegRequired)
            )
          );
        }
      });
    },
    handleFetchContentStreamUrl(response, contentItem) {
      contentItem.streamUrl = response.data.streamfilename;
      contentItem.scrubbing =
        response.data.packagedfilelist &&
        response.data.packagedfilelist.scrubbing
          ? response.data.packagedfilelist.scrubbing
          : "";
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
    goBack() {
      this.$router.push({ path: "/" });
    },
    getContentDetail(contentid) {
      let payload = {
        contentid: contentid,
        params: {
          displaylanguage: this.localDisplayLang,
        },
      };

      if (this.checkMpegRequired() === true) {
        payload.params.mpegtspackage = "YES";
      }
      store
        .dispatch("contentDetail", payload)
        .then((response) => {
          if (!response.data.reason) {
            this.contentRenderSetup(response);
          } else {
            eventBus.$emit(
              `contentDetail-error-response-${payload.contentid}`,
              response
            );
          }
        })
        .catch((error) => console.log(error));
    },
    getLatestSeason() {
      for (let i = 1; i <= this.seasonCount; i++) {
        this.seasonCountArray.push(i);
      }
      this.seasonNum = this.seasonCountArray.length;
    },
    getPoster() {
      let content = this.contentDetails;
      if (
        content &&
        content.poster &&
        content.poster.length &&
        !content.inwatchlist
      ) {
        let index = content.poster.findIndex((element) => {
          return element.postertype === "LANDSCAPE";
        });

        if (index > -1) {
          return content.poster[index].filelist[1].filename;
        }
      } else if (content.inwatchlist) {
        return content.poster.landscape;
      }
    },
    manageReadMore() {
      this.readMore = true;
    },
    manageReadLess() {
      this.readMore = false;
    },
    fetchTabList() {
      if (
        this.contentDetails.objecttype == 'CONTENT' &&
        this.contentDetails.trailer &&
        this.contentDetails.trailer.length > 0
      ) {
        this.tabList = ["Trailer", "Related"];
      } else if (this.contentDetails.objecttype == 'CONTENT') {
        this.tabList = ["Related"];
      } else if (
        this.contentDetails.objecttype == 'SERIES' &&
        this.contentDetails.trailer &&
        this.contentDetails.trailer.length > 0
      ) {
        this.tabList = ["Episodes", "Trailer", "Related"];
        if (
          this.contentDetails.tags &&
          this.contentDetails.tags[0].toLowerCase() === "upcoming"
        ) {
          this.tabList.splice(0, 1);
        }
      } else if (
        this.contentDetails.objecttype == 'SERIES' &&
        !this.contentDetails.trailer &&
        this.contentDetails.tags && this.contentDetails.tags.length && 
        this.contentDetails.tags[0].toLowerCase() === "upcoming"
      ) {
        this.tabList = ["Related"];
      }  else if (
        // this.contentDetails.tags.toString().toLowerCase().includes('ct-music') &&
        !this.contentDetails.trailer
      ) {
        this.tabList = ["Episodes", "Related"];
      } else if (
        this.contentDetails.objecttype == 'SERIES' &&
        !this.contentDetails.trailer
      ) {
        this.tabList = ["Episodes", "Related"];
      } else if (this.contentDetails.objecttype == 'SERIES') {
        this.tabList = ["Episodes", "Trailer", "Related"];
      }
    },
    selectTab(index, item) {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      this.selectTabUI();
      this.selectDefaultTab(index);
      if (item) {
        if (this.contentDetails.objecttype == 'SERIES') {
          if (item == "Episodes" || item == "Tracks" ) {
            this.selectedSection = index;
            this.$router.push({
              name: "episodes",
              params: { id: this.seasonNum, lang: currentLanguage },
            });
          } else if (item == "Trailer") {
            this.selectedSection = index;
            this.$router.push({
              name: "trailer",
              params: { lang: currentLanguage },
            });
          } else if (item == "Related") {
            this.selectedSection = index;
            this.$router.push({
              name: "related",
              params: { lang: currentLanguage },
            });
          }
        } else {
          if (item == "Trailer") {
            this.selectedSection = index;
            this.$router.replace({
              name: "movieTrailer",
              params: { lang: currentLanguage },
            });
          } else if (item == "Related") {
            this.selectedSection = index;
            this.$router.replace({
              name: "relatedMovie",
              params: { lang: currentLanguage },
            });
          }
        }
      }
    },

    selectTabUI() {
      let fullPath = this.$route.fullPath.split("/");
      let selectedSectionPath = fullPath[fullPath.length - 1];
      var sectionLowercase = this.tabList.map((v) => v.toLowerCase());
      let sectionIndex = sectionLowercase.indexOf(selectedSectionPath);
      if (sectionIndex == -1) {
        sectionIndex = this.selectedSection;
      } else {
        this.selectedSection = sectionIndex;
      }
    },
    selectDefaultTab(index) {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (this.contentDetails.objecttype == 'SERIES') {
        if (this.$router.currentRoute.name == "detailPage") {
          this.selectedSection = index;
          this.$router.replace({
            name: "episodes",
            params: { id: this.seasonNum, lang: currentLanguage },
          });
        } else if (
          this.$router.currentRoute.name == "detailPage" &&
          this.contentDetails.tags[0].toLowerCase() === "upcoming" &&
          this.contentDetails.trailer
        ) {
          this.selectedSection = index;
          this.$router.replace({
            name: "trailer",
            params: { lang: currentLanguage },
          });
        } else if (
          this.$router.currentRoute.name == "detailPage" &&
          this.contentDetails.tags[0].toLowerCase() === "upcoming" &&
          !this.contentDetails.trailer
        ) {
          this.selectedSection = index;
          this.$router.replace({
            name: "related",
            params: { lang: currentLanguage },
          });
        }
      } else if (this.contentDetails.objecttype == 'CONTENT' && this.contentDetails.category != 'MOVIE') {
        this.selectedSection = index;
          this.$router.replace({
            name: "related",
            params: { lang: currentLanguage },
          }).then(() => {
            let urlInfo = window.location.pathname;
            urlInfo = urlInfo.substring(0, urlInfo.length - 1);
            window.history.replaceState({}, document.title, urlInfo);
          })
          
      } else {
        if (
          this.$router.currentRoute.name == "detailPageMovie" &&
          this.contentDetails.objecttype == 'CONTENT' &&
          this.contentDetails.trailer
        ) {



          this.selectedSection = index;
          this.$router.replace({
            name: "movieTrailer",
            params: { lang: currentLanguage },
          }).then(() => {
            let urlInfo = window.location.pathname;
            urlInfo = urlInfo.substring(0, urlInfo.length - 1);
            window.history.replaceState({}, document.title, urlInfo);
          })

      


        } else if (
          this.$router.currentRoute.name == "detailPageMovie" &&
          this.contentDetails.objecttype == 'CONTENT' &&
          !this.contentDetails.trailer
        ) {
     
          
          this.selectedSection = index;
          this.$router.replace({
            name: "relatedMovie",
            params: { lang: currentLanguage },
          }).then(() => {
            let urlInfo = window.location.pathname;
            urlInfo = urlInfo.substring(0, urlInfo.length - 1);
            window.history.replaceState({}, document.title, urlInfo);
          })

      
        } else if (
        this.$router.currentRoute.name == "movieTrailer" &&
        this.contentDetails.objecttype == 'CONTENT' &&
        this.contentDetails.trailer
       ) {

        this.selectedSection = index;
       
        this.$router.replace({ name: "movieTrailer",  params: { lang: currentLanguage }  });
      
       }  else {
          this.selectedSection = index;      
     
          this.$router.replace({ name: "relatedMovie",  params: { lang: currentLanguage }  }).then(() => {
            let urlInfo = window.location.pathname;
            urlInfo = urlInfo.substring(0, urlInfo.length - 1);  
            window.history.replaceState({}, document.title, urlInfo);
          })
       

         }
        
      }
    },
    watchTrailer() {
      //To aviod drm check.
      if (
        this.contentDetails.trailer &&
        this.contentDetails.trailer.length > 0
      ) {
        this.showPlayer();
        this.loadContentMetaData(this.contentDetails, null);
        this.contentDetails.isDrmContent = false;
        this.contentDetails.trailerUrl = this.contentDetails.trailer[0].filelist[0].filename;

        let locationArr = location.pathname.split("/");

        window.history.pushState(
          "object or string",
          {},
          "/" +
            locationArr[1] +
            "/" +
            locationArr[2] +
            "/" +
            locationArr[3] +
            "/trailer/" +
            this.contentDetails.trailer[0].trailerid +
            "/play"
        );

        let contentList = [this.contentDetails];
        let playlist = this.buildPlayerPlaylist(contentList, true, 0);
        this.playerInstance.loadPlaylist(playlist, contentList.length);
        this.playerInstance.loadContent(0).then(() => {
          this.playerInstance.play().then(() => {
            console.log("Playback successful in detail page");
          });
        });
      } else {
        this.$router.push({ name: "related" });
      }
    },
    showPlayer() {
      this.playerInstance.blowUpPlayer();

      this.playerInstance.registerEvent(
        "currentcontentended",
        this.loadNextContentData
      );
      this.playbackInitiated = true;
      eventBus.$emit("player-visibility", true);
    },
    loadContentMetaData(contentMetaData, seriesMetaData) {
      let metaData = {
        content: contentMetaData,
        seriesContent: seriesMetaData,
      };

      eventBus.$emit("load-player-content", metaData);
    },
    buildPlayerPlaylist(episodeList, isTrailer, startIndex) {
      let playlist = [];
      let contentList = [this.contentDetails];
      for (var aIndex = startIndex; aIndex < episodeList.length; aIndex++) {
        let playlistItem = this.createPlayerPlaylistItem(
          episodeList[aIndex],
          isTrailer,
          contentList[0].watchedTime ? contentList[0].watchedTime : 0
        );
        playlist.push(playlistItem);
      }

      return playlist;
    },
    loadNextContentData(endedContentIndex) {
      this.hidePlayer();
    },
    hidePlayer() {
      this.playerInstance.shrinkPlayer();

      eventBus.$emit("player-visibility", false);

      let payload = { content: null, seriesContent: null };
      eventBus.$emit("load-player-content", payload);

      this.playerInstance.unloadPlayer(true);
    },
    createPlayerPlaylistItem(content, isTrailer, contentHead) {
      let payload = {
        contentId:
          content.objectid != undefined && content.objectid != ""
            ? content.objectid
            : "",
        contenttype: content.category,
        title: content.title,
        description: content.shortdescription,
        thumbnail: this.extractThumbnailPoster(content),
        source: isTrailer
          ? [content.trailerUrl]
          : content.streamUrl != undefined && content.streamUrl != ""
          ? [content.streamUrl]
          : [],
        licenseServer: this.appConfig.providerDetails.drmLicenseUrl,
        packageid: content.playback_details
          ? content.playback_details.packageid
          : "",
        providerid: this.appConfig.providerDetails.providerId,
        drmscheme: content.playback_details
          ? content.playback_details.drmscheme
          : "",
        availabilityid: content.playback_details
          ? content.playback_details.availabilityid
          : "",
        providerSession: localStorage.getItem("sessionToken"),
        skip:
        !isTrailer && content.skip && Array.isArray(content.skip) && content.skip.length > 0
            ? content.skip
            : "",
        position: contentHead,
        scrubbing: content.scrubbing ? content.scrubbing[0] : "",
        adversity: {
          advisory: content.advisory,
          pgrating: content.pgrating,
          defaultgenre: content.defaultgenre || content.genre,
        },
      };

      payload.isDrmContent = this.isDrmFree ? true : false;

      let playlistItem = new vLivePlaylistItem(payload);

      return playlistItem;
    },

    extractThumbnailPoster(contentItem) {
      let posterKey = contentItem.poster;
      let thumbNailUrl = null;

      if (
        posterKey &&
        Array.isArray(posterKey) == true &&
        posterKey.length > 0
      ) {
        // let posterFileList = posterKey[0].filelist;

        let posterFileList = posterKey.find(
          (posterKeyNode) => posterKeyNode.postertype == "LANDSCAPE"
        ).filelist;

        if (
          posterFileList &&
          Array.isArray(posterFileList) == true &&
          posterFileList.length > 0
        ) {
          let thumbNailNode = posterFileList.find(
            (posterNode) => posterNode.quality == "THUMBNAIL"
          );

          if (thumbNailNode) {
            thumbNailUrl = thumbNailNode.filename;
          }
        }
      }

      return thumbNailUrl;
    },
    createLink(medium) {
      this.shareLink = window.location.href;
    },
    copyLink() {
      navigator.clipboard.writeText(this.shareLink);
      showSnackBar("Link copied to clipboard");
    },
  },
  beforeDestroy() {
    eventBus.$off(`contentDetail-response-${this.contentId}`);
    eventBus.$off("changeLanguageView");
  },
  mixins: [Utility],
};
</script>

<style lang="scss">
  @import "@/sass/_variables.scss";
  @import "@/sass/_components.scss";
  @import "./detailPage.scss";
</style>
